import { setComponents } from '@storyblok/react';

import n4Components from 'components/bloks/n4';
import homeComponents from 'components/bloks/n4/home';
import GenericStory from 'components/layout/generic_story';
import N4Layout from 'components/layout/n4_layout';
import logger from 'lib/logger';
import { sbStaticProps } from 'lib/storyblok';

const log = logger({ category: '404' });

const Custom404 = ({ folder, story, preview, ...props }) => {
  setComponents({ ...n4Components, ...homeComponents });
  return <GenericStory folder={folder} story={story} preview={preview} {...props} />;
};
Custom404.getLayout = (children, pageProps) => <N4Layout {...pageProps}>{children}</N4Layout>;

const getStaticProps = async () => {
  const results = await sbStaticProps({
    folder: '/',
    slug: '404',
    origin: '404',
    version: 'draft',
    log,
  });
  return results;
};

export { getStaticProps };

export default Custom404;
